import React, { useState, useEffect } from "react";
import "../../assets/MainResult.css";
import { useLocation, useNavigate } from "react-router-dom";
import { convertTimeFormat, getmarketData, results } from "../../server/api";
import { getLinkData } from '../../server/api';

function RajaRaniMorning() {
  const location = useLocation();
  const [showButtons, setShowButtons] = useState(true);
  const [marketData, setMarketData] = useState([]);
  const [appLink, setAppLink] = useState("");
  const navigate = useNavigate();

  function handleJodi(_id) {
    navigate(`/jodi/${_id}`);
  }

  function handlePanel(_id) {
    navigate(`/panel/${_id}`);
  }

  useEffect(() => {
    setShowButtons(location.pathname === "/");

    const fetchMarketData = async () => {
      try {
        const response = await getmarketData();
        if (response && response.data && Array.isArray(response.data)) {
          setMarketData(response.data);
        }
      } catch (error) {
        console.error("Error fetching market data:", error);
      }
    };

    const fetchAppLink = async () => {
      try {
        const response = await getLinkData(); // Make sure you have this API function
        if (response && response.data) {
          setAppLink(response.data.app_link);
        }
      } catch (error) {
        console.error("Error fetching app link:", error);
      }
    };

    fetchMarketData();
    fetchAppLink();
  }, [location.pathname]);

  return (
    <div>
      {marketData.map((data) => (
        <div
          key={data._id}
          className={`flex-container ${showButtons ? "flex-display" : ""}`}
          style={{
            backgroundColor:
              data.name === "KALYAN" || data.name === "MAIN BAZAR"
                ? "yellow"
                : "",
          }}
        >
          {showButtons && (
            <div className="leftbtn-container">
              <button onClick={() => handleJodi(data._id)}>
                Jodi
              </button>
            </div>
          )}
          <div className="content-container">
            <h2
              className="center-text"
              style={{ textShadow: "1px 1px 2px #FF0000" }}
            >
              {data.name}
            </h2>
            <p className="center-text">
              {results(
                data.open_panna,
                data.open_digit,
                data.close_digit,
                data.close_panna
              )}
            </p>
            <span className="center-text">
              {convertTimeFormat(data.open_time)} - {convertTimeFormat(data.close_time)}
            </span>
            <div className="app-buttons-container">
              <button 
                className="play-button"
                onClick={() => window.open(appLink)}
              >
                Play Online
              </button>
            </div>
          </div>
          {showButtons && (
            <div className="rightbtn-container">
              <button onClick={() => handlePanel(data._id)}>Panel</button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default RajaRaniMorning;