import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer/Footer";
import { getresultData } from "../../../server/api";
import { useLocation, useParams } from "react-router-dom";
import { RefreshButton } from "../../../components/Buttons/RefreshButton";
import {
  BottomtoTop,
  ToptoBottom,
} from "../../../components/Buttons/TopBottomButton";
import './Panel.css'

const Panel = () => {
  const [weekdate, setWeekdate] = useState([]);
  const [name, setName] = useState('')
  let weeks = ['Sun', 'Mon', 'Tue', 'Wed', 'The', 'Fri', 'Sat']
  var arr = []
  const params = useParams()
  console.log(params.id)
  const fetchMarketData = async () => {
    try {
      const response = await getresultData(params.id);
      if (response && response.data && Array.isArray(response.data)) {
        setData(response.data);
        setName(response.data[0].market_name)
      }
    } catch (error) {
      console.error("Error fetching market data:", error);
    }
  };
  const setData = (resdata) => {
    var data = []
    var obj = {}
    for (var i = 0; i <= resdata.length - 1; i++) {
      var date = new Date(resdata[i].from)
      date = (date.getMonth() + 1) + "/"
        + date.getDate() + "/"
        + date.getFullYear();
      var res1 = resdata[i].open_result !== undefined ? resdata[i].open_result : '*** - *'
      let parts = resdata[i].close_result !== undefined ? resdata[i].close_result : '*** - *'
      parts = parts.split(' - ');

      var res2 = parts[1] + ' - ' + parts[0];
      obj[date] = res1 + res2;
      // console.log(obj)
      data.push(obj)
      obj = {}
    }
    setWeekdate(data)
    return
  }

  const getWeekDates = (weekIndex) => {
    const today = new Date();
    const currentDay = today.getDay();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - currentDay + (1 - weekIndex * 7)); // Set to Monday of the week
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() - currentDay + (7 - weekIndex * 7)); // Set to Sunday of the week

    return { startDate: startOfWeek, endDate: endOfWeek };
  };
  const getallDates = (day, weekIndex) => {
    const today = new Date();
    const currentDay = today.getDay();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - currentDay + (day - weekIndex * 7)); // Set to Monday of the week

    var start = formatDate(startOfWeek)
    return start
  };
  const formatDate = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      var newdate = date.toLocaleDateString(options)
      newdate = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
      return newdate;
    } else {
      return ''; // Return empty string if date is invalid
    }
  };

  const funcs = (item) => {

    for (var i = 0; i <= weekdate.length - 1; i++) {
      var obj = weekdate[i]
      var keys;
      var vals;
      Object.entries(obj).map(([key, val], i) => {
        keys = key;
        vals = val
      })
      if (item == keys) {

        //  console.log(weekdate)

        var d = getCss(vals)
        return d
      }
    }
    return getCss('*** - ** - ***')
  }
  const getCss = (vals) => {
    var part = vals.split(" - ")
    var n = Math.abs(Number(part[1][0]) - Number(part[1][1]))
    var s = false
    if (n == 0 || n == 5) {
      s = true
    }
    return <div className={s == true ? 'red' : 'black'}>
      <p>{part[0][0]} <br /> {part[0][1]} <br /> {part[0][2]} </p>
      <p className='Wet'>{part[1]}</p>
      <p>{part[2][0]} <br /> {part[2][1]} <br /> {part[2][2]}</p>
    </div>
  }

  for (var i = 1; i <= 7; i++) {
    var date = getallDates(i, 1);
    var day = (new Date(date)).getDay()
    arr.push(weeks[day])
    // console.log(day)
  }

  useEffect(() => {


    fetchMarketData();
  }, [])


  return (
    <>
      <div className="jodihead">
        <h2>Kalyan Badshah Matka</h2>
      </div>
      <div className="secondrow"> {name} PANEL CHART</div>

      <div className="chartjodi">
        <h2>{name} PANEL RESULT CHART RECORDS</h2>
        <p>{/* Description */}</p>
      </div>
      <div className="main-container">
        <div className="text">
          <div className="resultbtn">
            <RefreshButton />
          </div>
        </div>

        <div className="btncontainers">
          <ToptoBottom />
          <div className="jodichart">PANEL CHART</div>

          <div className="container">
            <div className="joditable">
              <table>
                <thead>
                  <tr>
                    <th>
                      {" "}
                      <p>Date</p>
                    </th>
                    {arr.map((item, index) => (
                      <th key={index}>
                        <p>{item}</p>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Array.from({ length: 10 }).map((_, index) => {
                    const { startDate, endDate } = getWeekDates(index);
                    return (
                      <tr key={index}>
                        <td className='tdName'>
                          <div className="Td">
                            <p>
                              {formatDate(startDate)} <br /> to <br /> {formatDate(endDate)}
                            </p>
                          </div>
                        </td>
                        {/* <td>
                                        <div>
                                        <p>2 <br />4 <br /> 3</p>
                                        <p>56</p>
                                        <p>2 <br />4 <br /> 3</p>
                                        </div>
                                        </td> */}

                        <td>{funcs(getallDates(1, index))}</td>
                        <td>{funcs(getallDates(2, index))}</td>
                        <td>{funcs(getallDates(3, index))}</td>
                        <td>{funcs(getallDates(4, index))}</td>
                        <td>{funcs(getallDates(5, index))}</td>
                        <td>{funcs(getallDates(6, index))}</td>
                        <td>{funcs(getallDates(7, index))}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="text">
          <div className="resultbottm">
            <RefreshButton />
          </div>
        </div>
        <div className="btncontainers">
          <BottomtoTop />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Panel;
