import axiosInstance from './axiosConfig';

// Simple GET request
export const getresultData = async (marketId) => {
  // console.log(marketId,"marketidddddddddddd")
  try {
    const response = await axiosInstance.get(`public/results?market=${marketId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching result data:', error);
    throw error;
  }
};

export const getLinkData = async (marketId) => {
  // console.log(marketId,"marketidddddddddddd")
  try {
    const response = await axiosInstance.get(`public/links`);
    return response;
  } catch (error) {
    console.error('Error fetching link data:', error);
    throw error;
  }
};
export const getSliderData = async (marketId) => {
  // console.log(marketId,"marketidddddddddddd")
  try {
    const response = await axiosInstance.get(`public/get_slider`);
    return response.data;
  } catch (error) {
    console.error('Error fetching slider data:', error);
    throw error;
  }
};
export const getmarketData = async () => {
  try {
    const response = await axiosInstance.get('public/markets?tag=global');
    // console.log(response,"test data")
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const getmarketData2 = async () => {
  try {
    const response = await axiosInstance.get('public/markets?tag=starline');
    console.log(response,"test data")
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const results = (openPana, openDigit, closeDigit, closePana)=> {
  let data = "";
  if (openPana === "-") {
      data = "***";
  } else {
      data = openPana;
  }
  data = data + "-";
  if (openDigit === "-") {
      data = data + '*';
  } else {
      data = data + openDigit;
  }

  if (closeDigit === "-") {
      data = data + '*';
  } else {
      data = data + closeDigit;
  }
  data = data + "-";
  if (closePana === "-") {
      data = data + "***";
  } else {
      data = data + closePana;
  }

  return data;
}
const results2 = (openPana, openDigit, closeDigit, closePana)=> {
  let data = "";
  if (openPana === "-") {
      data = "***";
  } else {
      data = openPana;
  }
  data = data + "-";
  if (openDigit === "-") {
      data = data + '*';
  } else {
      data = data + openDigit;
  }

  return data;
}
const convertTimeFormat = (inputTime) =>{
// console.log('first',inputTime)
  const date = new Date(`2000-01-01T${inputTime}:00`);

  const hours = date.getHours();
  const minutes = date.getMinutes();

  
  const formattedHours = hours % 12 || 12;

  
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Determine if it's AM or PM
  const period = hours < 12 ? "AM" : "PM";

 
  const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;
// console.log('first',formattedTime)
  return formattedTime;
}
const convertTimeFormat2 = (inputTime) =>{

  var inputarr = inputTime.split(":");
  var m = inputarr[0] < 10 ? `0${parseInt(inputarr[0])}` : inputarr[0];
     const date = new Date(`2000-01-01T${m}:${inputarr[1]}:00`);
   
     const hours = date.getHours();
     const minutes = date.getMinutes();
   
     
     const formattedHours = hours % 12 || 12;

     
     const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
 
     // Determine if it's AM or PM
     const period = hours < 12 ? "AM" : "PM";
   
    
     const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;
  // console.log('first',formattedTime)
     return formattedTime;
   }
export {results,results2,convertTimeFormat,convertTimeFormat2}