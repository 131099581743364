import React from 'react'
import './MyFooter.css'

const MyFooter = () => {
    return (
        <>
            <div className="mainfooter">
                <div className="firstfoot">
                    <h3>-: DISCLAIMER :-</h3>
                    <p>
                    Our website is operated by Kalyan Badshah Matka International B.V., a company
                     established under the laws of United Kingdom, with registered address at #506 
                     IT Park Towers Cambridge CB2 1TN, United Kingdom, and having its gaming sublicence 
                     issued, by United Kingdom e-Gaming and all rights to operate the gaming software worldwide
                            </p>
                </div>
                {/* <div className="secondfoot">
                    <h3>Visit Kalyan777</h3>
                    <h3>© 2014-2024 All Rights Reserved</h3>
                    <h2>Website and App बनवाने और SEO करवाने के लिए सम्पर्क करें:</h2>
                    <h3>Contact Admin</h3>
                    <button>Call- 7847031754</button>
                </div> */}
                <div className="thirdfoot">
                    <h4>© 2010 - 2024 https://kalyanbadshahmatkaapp.in.net/</h4>
                    <div className='flexitems'>
                        <h5>Terms & Conditions</h5>
                        |
                        <h5> Privacy Policy</h5>
                    </div>
                    <div className='flexitems'>
                        <h5>About Us </h5>
                        |
                        <h5>Contact Us</h5>
                    </div>
                    <div className="fourthfoot">
                        POWERD BY Kalyan Badshah
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyFooter
